import { button } from "@components/solid/classLists";
import { getNextRate } from "@components/solid/Player/lib/getNextRate";
import { type Component, type JSX } from "solid-js";

export const PlaybackRateButton: Component<{
  rate: number;
  onClick: JSX.EventHandler<HTMLButtonElement, Event>;
}> = (props) => {
  return (
    <button
      onClick={props.onClick}
      aria-label={`Sett avspillingshastighet til ${getNextRate(props.rate)}`}
      classList={button({ type: "ghost" })}
    >
      {props.rate}x
    </button>
  );
};
