import { classesToClassList } from "@components/solid/classLists";
import type { ScrubberValues } from "@nrk/player-core";
import { durationByUnitType } from "@utils/date";
import dayjs from "dayjs";
import { createEffect, createSignal, type Component, type JSX } from "solid-js";
import styles from "./Scrubber.module.css";

type ScrubberProps = ScrubberValues & {
  isLive: boolean;
  colorVariant: "red" | "blue";
  onInput: JSX.EventHandler<HTMLInputElement, Event>;
};

const formatTimestamp = (seconds: number) => {
  if (seconds / 3600 < 1) {
    return durationByUnitType(Math.floor(seconds), "seconds").format("mm:ss");
  }
  return durationByUnitType(Math.floor(seconds), "seconds").format("HH:mm:ss");
};
export const Scrubber: Component<ScrubberProps> = (props) => {
  const [playheadPosition, setPlayheadPosition] = createSignal(
    props.playheadPosition,
  );

  // Update playhead position when props change. Value will not update on mount without a signal for some reason.

  createEffect(() => {
    setPlayheadPosition(props.playheadPosition);
  });

  return (
    <div class={styles.scrubberWrapper}>
      <p
        classList={{
          ...classesToClassList("nrk-typography-caption--1", styles.timestamp),
          [styles.timestampShade]: props.isLive,
        }}
      >
        {props.startLiveTime
          ? dayjs(props.startLiveTime).format("HH:mm:ss")
          : formatTimestamp(props.playheadDisplayTime)}
      </p>
      <div class={styles.inputRangeWrapper}>
        <input
          classList={{
            ...classesToClassList(styles.inputRange),
            [styles.red]: props.colorVariant === "red",
          }}
          type="range"
          min={props.startPosition}
          max={props.endPosition}
          value={playheadPosition()}
          onInput={props.onInput}
        />
      </div>
      <p
        classList={{
          ...classesToClassList("nrk-typography-caption--1", styles.timestamp),
          [styles.timestampShade]: !props.isLive,
        }}
      >
        {props.endLiveTime
          ? dayjs(props.endLiveTime).format("HH:mm:ss")
          : formatTimestamp(props.endDisplayTime)}
      </p>
    </div>
  );
};
